import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import CloseIcon from 'mdi-material-ui/Close'
import { ApiEndpoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'
import { MuiOtpInput } from 'mui-one-time-password-input'
import { axiosInstance } from 'src/network/adapter'

const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .required('OTP is required')
    .matches(/^\d{4}$/, 'OTP must be a number'),
  password: yup
    .string("Enter Valid Password Format")
    .min(8)
    .required("Enter Password"),
  confirm_password: yup
    .string("Enter Valid Confirm Password Format")
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .min(8)
    .required("Enter Confirm Password"),
})

const DialogResetPassword = props => {
  const { open, toggle, email, setResetDialogFormDialogOpen, setLoginFormDialogOpen, setForgetFormDialogOpen } = props
  const [loading, setLoading] = useState(false)
  const [otp, setOtp] = useState('')
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [resendLoading, setResendLoading] = useState(false)

  const handleOtpChange = newValue => {
    setOtp(newValue)
  }

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  })

  const onSubmit = data => {
    setLoading(true)
    const payload = {
      email: email,
      otp: data.otp,
      password: data.password
    }

    axiosInstance
      .post(ApiEndpoints.AUTH.resetPassword, payload)
      .then(response => response.data)
      .then(response => {
        toastSuccess(response.message)
        setForgetFormDialogOpen(false)
        setResetDialogFormDialogOpen(false)
        setLoginFormDialogOpen(true)
      })
      .catch(error => {
        toastError(error)
      })
      .finally(() => {
        setLoading(false)
        reset({oto:'',password:''})
      })
  }

  const handleResendOtp = () => {
    setResendLoading(true)

    // Call the forget password API to resend OTP
    axiosInstance
      .post(ApiEndpoints.AUTH.forgetPassword, { email })
      .then(response => {
        toastSuccess('OTP has been resent')
      })
      .catch(error => {
        toastError(error.message)
      })
      .finally(() => {
        setResendLoading(false)
      })
  }

  useEffect(() => {
    if (open) {
      reset({
        otp: '',
        password: '',
        confirm_password:''
      })
      setOtp('')
    }
  }, [open, reset])

  return (
    <Dialog sx={{ '& .MuiPaper-root': { borderRadius: '30px' } }} open={open} fullWidth maxWidth='xs' scroll='paper'>
      <DialogContent>
        <form id='add-driver' onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontFamily: 'Ivy Mode ,sans-serif',
                    fontWeight: 400,
                    fontSize: { xs: '24px', md: '30px' },
                    lineHeight: { xs: '32px', md: '37px' },
                    color: '#5B2E1E'
                  }}
                >
                  Reset Password
                </Typography>
                <IconButton aria-label='close' onClick={toggle} sx={{ color: theme => theme.palette.grey[500] }}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Divider sx={{ mb: '20px' }} />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography variant='body2' fontWeight={600}>
                    Enter OTP
                  </Typography>
                  <FormControl fullWidth>
                    <Controller
                      name='otp'
                      control={control}
                      render={({ field }) => (
                        <MuiOtpInput
                          {...field}
                          value={otp}
                          onChange={newValue => {
                            handleOtpChange(newValue)
                            field.onChange(newValue)
                          }}
                        />
                      )}
                    />
                    {errors.otp && <FormHelperText sx={{ color: '#ff0000' }}>{errors.otp.message}</FormHelperText>}
                  </FormControl>
                  <Box display='flex' justifyContent='flex-end' mt={1}>
                    <Typography
                      variant='body2'
                      sx={{ color: '#905A47', cursor: 'pointer' }}
                      onClick={handleResendOtp}
                      disabled={resendLoading}
                    >
                      {resendLoading ? 'Resending OTP...' : 'Resend OTP'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth >
                    <Controller
                      name='password'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='outlined'
                          error={Boolean(errors.password)}
                          value={value}
                          onChange={onChange}
                          id='password'
                          placeholder='Enter your New Password'
                          type={showNewPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => setShowNewPassword(!showNewPassword)}
                                  onMouseDown={e => e.preventDefault()}
                                >
                                  {showNewPassword ? <VisibilityOutlinedIcon sx={{ color: '#905A47' }} /> : <VisibilityOffOutlinedIcon sx={{ color: '#905A47' }} />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                    {errors.password && (
                      <FormHelperText error>
                        {errors.password.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <Controller
                      name='confirm_password'
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          variant='outlined'
                          error={Boolean(errors.confirm_password)}
                          value={value}
                          onChange={onChange}
                          id='confirm_password'
                          placeholder='Enter your Confirm Password'
                          type={showConfirmPassword ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton
                                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                  onMouseDown={e => e.preventDefault()}
                                >
                                  {showConfirmPassword ? <VisibilityOutlinedIcon sx={{ color: '#905A47' }} /> : <VisibilityOffOutlinedIcon sx={{ color: '#905A47' }} />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      )}
                    />
                    {errors.confirm_password && (
                      <FormHelperText error>
                        {errors.confirm_password.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Button
                    size='small'
                    sx={{ width: '100%', bgcolor: '#000000' }}
                    type='submit'
                    form='add-driver'
                    variant='contained'
                    loading={loading}
                  >
                      {loading ? "Submitting..." : "Reset"} 
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default DialogResetPassword
