const input = theme => {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.neutral[70],
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '22px',
          marginBottom: 6
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '16px',
          margin: '8px 0 0',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.secondary
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "red"
          },
          color: "white", // if you also want to change the color of the input, this is the prop you'd use
        },
        root: {
          '&:before': {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.22)`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.32)`
          },
          '&.Mui-disabled:before': {
            borderBottomStyle: 'solid'
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "red"
          },
          color: "white", // if you also want to change the color of the input, this is the prop you'd use
        },
        root: {
          borderRadius: 99,
          backgroundColor: `rgba(${theme.palette.customColors.main}, 0.04)`,
          '&:hover:not(.Mui-disabled)': {
            backgroundColor: `rgba(${theme.palette.customColors.main}, 0.08)`
          },
          '&:before': {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.22)`
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid rgba(${theme.palette.customColors.main}, 0.32)`
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: "#000000"
          },
          color: "#000000",
        },
        '&:hover fieldset': {
          borderColor: '#905A47',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#905A47',
        },
        borderColor: '#905A47',
        root: {
          // borderColor: '#905A47',
          // '& .MuiOutlinedInput-root': {
          //   '& fieldset': {
          //     borderColor: '#905A47',
          //   },
          //   '&:hover fieldset': {
          //     borderColor: '#905A47',
          //   },
          //   '&.Mui-focused fieldset': {
          //     borderColor: '#905A47',
          //   },
          // },
          // borderRadius: ,
          '&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColor: '#905A47'
          },
          '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#905A47'
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#905A47'
          }
        }
      }
    }
  }
}

export default input
