import { Box, Grid, useMediaQuery } from '@mui/material'
import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import Logo from 'src/@core/components/logo'
import { useSettings } from 'src/@core/hooks/useSettings'
// import LoginBanner from 'src/assets/images/login-banner.png'
// import SignupBanner from 'src/assets/images/signup-banner.png'
import { useLocation } from 'react-router-dom'

const LeftWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  // padding: '24px',
  display: 'flex'
}))

const LeftLoginContainer = styled(Box)(({ theme }) => ({
  borderRadius: 0,
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  background: '#8C77B8',
  //backgroundImage: `url(${LoginBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}))

const LeftSignupContainer = styled(Box)(({ theme }) => ({
  borderRadius: 0,
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  background: '#8C77B8',
  //backgroundImage: `url(${SignupBanner})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center'
}))

const LoginIllustrationWrapper = styled(Box)(({ theme }) => ({
  marginTop: '125px',
  padding: '25px',
  maxWidth: '400px',
  alignItems: 'center'
}))

const RightWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex'
  // margin: 'auto',
  // [theme.breakpoints.up('md')]: {
  //     maxWidth: 550
  // }
}))

const AuthLayout = ({ children }) => {
  return (
    <>
      <Grid container>
        {/* {!hidden ? (
          <Grid item xs={12} md={7}>
            <LeftWrapper style={{ overflow: 'hidden' }}>
              {location.pathname === '/signup' ? <LeftSignupContainer /> : <LeftLoginContainer />}
            </LeftWrapper>
          </Grid>
        ) : (
          <></>
        )} */}
        <Grid item xs={12} sx={{ flex: 1, height: '100%' }}>
          <Box style={{ overflow: 'hidden', height: '100%' }}>
            <RightWrapper>
              {/* {
                                hidden && <Box display="flex" justifyContent="center" alignItems="center" marginBottom="2.5rem" marginTop="2.5rem">
                                    <Logo />
                                </Box>
                            } */}
              {children}
            </RightWrapper>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default AuthLayout
