// ** React Imports
import { Fragment, useEffect } from "react";

// ** Next Imports
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuiListItem from "@mui/material/ListItem";

// ** Third Party Imports
import clsx from "clsx";

// ** Theme Config Import
import themeConfig from "src/configs/themeConfig";

// ** Custom Components Imports
import UserIcon from "src/layouts/components/UserIcon";
import Translations from "src/layouts/components/Translations";
import CanViewNavLink from "src/layouts/components/acl/CanViewNavLink";

// ** Util Import
import { hexToRGBA } from "src/@core/utils/hex-to-rgba";
import {
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import Arrow from '../../.../../../../../assets/images/arrow.png'
import { ApiEndpoints } from "src/network/endpoints";
import { axiosInstance } from "src/network/adapter";
import { toastError } from "src/utils/utils";

const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: "auto",
  paddingTop: theme.spacing(2.25),
  color: "#000000",
  fontWeight: 500,
  paddingBottom: theme.spacing(2.25),
  "&:hover": {
    // backgroundColor: theme.palette.action.hover
    color: "#000000",
  },
  "&.active, &.active:hover": {
    //backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08)
    color: "#000000",
    fontWeight: 700,
  },
}));



const HorizontalNavLink = (props) => {
  const navigate = useNavigate();
  // ** Props
  const { item, settings, hasParent } = props;

  // ** Hook & Vars
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { navSubItemIcon, menuTextTruncate } = themeConfig;
  const Wrapper = !hasParent ? List : Fragment;
  const IconTag = item.icon ? item.icon : navSubItemIcon;

  const handleURLQueries = () => {
    if (Object.keys(searchParams).length && item.path) {
      const arr = Object.keys(searchParams);

      return (
        location.pathname.includes(item.path) &&
        location.pathname.includes(searchParams[arr[0]])
      );
    }
  };

  const isNavLinkActive = () => {
    if (location.pathname === item.path || handleURLQueries()) {
      return true;
    } else {
      return false;
    }
  };
  //services dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [collectionData, setCollectionData] = useState([])
  // Fetch collection data on component mount
  // useEffect(() => {
  //   if (item.title === "Collections") {
  //     axiosInstance
  //       .get(ApiEndpoints.COLLECTION.list)
  //       .then((response) => {
  //         setCollectionData(response.data);
  //       })
  //       .catch((error) => {
  //         toastError(error);
  //       });
  //   }
  // }, [item.title]);

  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  return (
    // <CanViewNavLink navLink={item}>
    <Wrapper
    // {...(!hasParent
    //   ? {
    //       component: 'div',
    //       sx: { borderBottom: 2, borderColor: 'common.white' }
    //     }
    //   : {})}
    >
      <ListItem
        to={`${item.path}`}
        component={Link}
        disabled={item.disabled}
        className={clsx({ active: isNavLinkActive() })}
        target={item.openInNewTab ? "_blank" : undefined}
        onClick={(e) => {
          if (item.path === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        sx={{
          ...(item.disabled
            ? { pointerEvents: "none" }
            : { cursor: "pointer" }),
          ...(!hasParent
            ? {
              height: "45px",
              width: "100%",
              px: 5.5,
              borderRadius: "10px",
              "&.active, &.active:hover": {
                // boxShadow: 3,
                backgroundColor: "",
                "& .MuiButton-root, & .MuiListItemIcon-root": {
                  color: "common.white",
                },
              },
            }
            : { px: 5 }),
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", gap: '10px'
          }}
        >
          <Box>
            {/* {item.title === "Collections" ? (
              <Box>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  // onClick={handleClick}
                  disableRipple
                  disableElevation
                  sx={{
                    p: 0,
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  Collections
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {collectionData?.data && collectionData?.data.map((collection) => (
                    <MenuItem
                      key={collection._id}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/rug-listing");
                        handleClose(e);
                      }}
                    >
                      {collection.title}
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            ) : ( */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  ...(menuTextTruncate && { overflow: "hidden" }),
                }}
              >
                <Translations text={item.title} />
              </Box>
            {/* )} */}
            
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...(menuTextTruncate && { overflow: "hidden" }),
            }}
          >
            <Translations text={item.title} />
          </Box> */}
          {/* {item.title === "Collections" && (
            <Box>
              {open ? (
                <img src={Arrow} alt=""
                  onClick={handleClick} />
              ) : (
                <img src={Arrow} alt="" onClick={handleClick} />
              )}
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {collectionData.data && collectionData.data.map((collection) => (
                  <MenuItem
                    key={collection._id}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/rug-listing");
                      handleClose(e);
                    }}
                  >
                    {collection.title}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )} */}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ...(menuTextTruncate && { overflow: "hidden" }),
          }}
        >
          {/* <ListItemIcon sx={{ color: "text.primary", mr: !hasParent ? 2 : 3 }}>
            <UserIcon
              icon={IconTag}
              componentType="horizontal-menu"
              iconProps={{
                sx:
                  IconTag === navSubItemIcon
                    ? { fontSize: "0.875rem" }
                    : { fontSize: "1.375rem" },
              }}
            />
          </ListItemIcon> */}
        </Box>
      </ListItem>


    </Wrapper>
    // </CanViewNavLink>
  );
};

export default HorizontalNavLink;
