import React, { useEffect, useState } from 'react'
import {
    Container,
    Box,
    Typography,
    Grid,
} from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Instagram from '../../assets/images/instagram.png'
import Facebook from '../../assets/images/facebook.png'
import Pinterest from '../../assets/images/pinterest.png'
import Linkin from '../../assets/images/linkin.png'
import Youtube from '../../assets/images/youtube.png'
import Logo from 'src/@core/components/logo'
import FooterImage from '../../assets/images/footer.svg'
import ContactForm from './ContactForm'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import { toastError } from 'src/utils/utils'

const links = [
    { path: '/about-us', label: 'About Us' },
    { path: '/upcoming-auctions', label: 'Upcoming Auctions' },
    { path: '/auction-blog', label: 'Auctions Blogs' },
    { path: '/faq', label: 'FAQs' },
    { path: '/contact-us', label: 'Contact Us' },
];

const Typo = {
    color: "#361B12",
    fontSize: '14px',
    lineHeight: '15px',
    fontWeight: 500,
};

const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let queryParams = new URLSearchParams();

    const handleCardClick = (item) => {
        queryParams.set("data", encodeURIComponent(JSON.stringify(item)));
        navigate(`/rug-listings?${queryParams.toString()}`);
    };

    const [collectionData, setCollectionData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        setLoading(true);
        axiosInstance
            .get(ApiEndpoints.COLLECTION.list)
            .then(response => {
                setCollectionData(response.data);
            })
            .catch(error => {
                toastError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <Box sx={{ bgcolor: "#F3F0EB", pt: 15, pb: 5, padding: { xs: '20px', sm: '30px', md: '50px' } }}>
                <Container maxWidth='xl'>
                    <Grid container>
                        <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                            <Logo />
                            <Box sx={{ width: '290px', textWrap: 'wrap' }}>
                                <Typography variant='fm-p3' fontWeight={'500'} color={'#000000'} lineHeight={'19px'}>
                                    Since 2010, Rug Auctions has been offering fine, rare, vintage, antique and modern decorative rugs to discerning customers and collectors worldwide.
                                </Typography>
                            </Box>
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular,sans-serif' }}>
                                Socials
                            </Typography>
                            <Box sx={{ display: "flex", gap: 2 }}>
                                <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                    <img src={Instagram} style={{ width: '44px', height: '44px' }} alt="Instagram" />
                                </a>
                                <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                    <img src={Facebook} style={{ width: '44px', height: '44px' }} alt="Facebook" />
                                </a>
                                <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer">
                                    <img src={Pinterest} style={{ width: '44px', height: '44px' }} alt="Pinterest" />
                                </a>
                                <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                    <img src={Linkin} style={{ width: '44px', height: '44px' }} alt="LinkedIn" />
                                </a>
                                <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                                    <img src={Youtube} style={{ width: '44px', height: '44px' }} alt="YouTube" />
                                </a>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={2}
                            sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
                        >
                            <Typography
                                sx={{
                                    color: "#361B12",
                                    fontSize: '20px',
                                    lineHeight: '16px',
                                    marginBottom: '12px',
                                    fontFamily: 'Ivy Mode regular',
                                    marginTop: { xs: '10px', sm: '10px', md: '0px' }
                                }}
                            >
                                Quick Links
                            </Typography>

                            {links.map((link) => {
                                const isActive = location.pathname === link.path;

                                return (
                                    <Link
                                        key={link.path}
                                        to={isActive ? '#' : link.path}
                                        style={{
                                            textDecoration: 'none',
                                            pointerEvents: isActive ? 'none' : 'auto'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                ...Typo,
                                                color: isActive ? 'gray' : '#361B12'
                                            }}
                                        >
                                            {link.label}
                                        </Typography>
                                    </Link>
                                );
                            })}
                        </Grid>
                        <Grid item xs={6} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular,sans-serif', marginTop: { xs: '10px', sm: '10px', md: '0px' } }}>
                                Category
                            </Typography>
                            {collectionData?.data?.length ? (
                                collectionData.data.slice(0, 6).map((item, index) => (
                                    <Typography
                                        key={index}
                                        sx={{ ...Typo, cursor: 'pointer' }}
                                        onClick={() => handleCardClick(item.title)}
                                    >
                                        {item?.title}
                                    </Typography>
                                ))
                            ) : (
                                <Typography>No collections available</Typography>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                            <Typography sx={{ color: "#361B12", fontSize: '20px', lineHeight: '16px', marginBottom: '12px', fontFamily: 'Ivy Mode regular,sans-serif', marginTop: { xs: '10px', sm: '10px', md: '0px' } }}>
                                Get Styling Assistance
                            </Typography>
                            <ContactForm />
                        </Grid>
                    </Grid>
                    <Box mt={'50px'}>
                        <img src={FooterImage} alt="Footer" style={{ width: '100%' }} />
                    </Box>
                    <Grid container direction={{ xs: 'column-reverse', sm: 'row' }} justifyContent="space-between">
                        <Grid item xs={12} sm={6} md={9}>
                            <Typography
                                sx={{
                                    fontSize: '14px',
                                    lineHeight: '30px',
                                    color: "#361B12",
                                    textAlign: { xs: 'center', sm: 'left' }
                                }}
                            >
                                © {new Date().getFullYear()} Rugauctions All Rights Reserved.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={3}
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'center', sm: 'flex-end' },
                                mb: { xs: 2, sm: 0 }
                            }}
                        >
                            <Box sx={{ display: 'flex', gap: 7 }}>
                                {['/privacy-policy', '/terms-and-condition'].map((path, index) => {
                                    const isActive = location.pathname === path;
                                    const linkText = index === 0 ? 'Privacy Policy' : 'Terms and Condition';
                                    return (
                                        <Link
                                            key={path}
                                            to={isActive ? '#' : path}
                                            style={{ textDecoration: 'none', pointerEvents: isActive ? 'none' : 'auto' }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    lineHeight: '30px',
                                                    color: isActive ? 'gray' : '#361B12'
                                                }}
                                            >
                                                {linkText}
                                            </Typography>
                                        </Link>
                                    );
                                })}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}

export default Footer;
