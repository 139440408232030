// ** React Imports
import { createContext, useEffect, useState } from 'react'

// ** Next Import
import { Routes, useNavigate, useSearchParams } from 'react-router-dom'

// ** Config
import authConfig from '../configs/auth'
import FallbackSpinner from 'src/@core/components/spinner'
import { ApiEndpoints } from 'src/network/endpoints'
import { axiosInstance } from 'src/network/adapter'
import toast from 'react-hot-toast'

// ** Defaults
const defaultProvider = {
  unReadNotificationCount: 0,
  setUnReadNotificationCount: 0,
  user: null,
  loading: true,
  otpVerifyData: null,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  signup: () => Promise.resolve(),
  getUnReadNotificationCount: () => Promise.resolve(),

}
const AuthContext = createContext(defaultProvider)

const AuthProvider = ({ children }) => {
  // ** States
  const [user, setUser] = useState(defaultProvider.user)
  const [loading, setLoading] = useState(defaultProvider.loading)
  const [isInitialized, setIsInitialized] = useState(defaultProvider.isInitialized)
  const [otpVerifyData, setOtpVerifyData] = useState(null)
  const [unReadNotificationCount, setUnReadNotificationCount] = useState(
    defaultProvider.unReadNotificationCount
  );


  // ** Hooks
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // const redirectUrl = searchParams.get("redirect_url");
  const getUnReadNotificationCount = () => {
    axiosInstance
      .get(ApiEndpoints.NOTIFICATIONS.get_unread_notification_count)
      .then((response) => response.data)
      .then((response) => {
        setUnReadNotificationCount(response?.count);
      })
      .catch((error) => {
      });
  };

  const initAuth = async () => {
    setIsInitialized(true)
    const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)
    if (storedToken) {
      setLoading(true)
      await axiosInstance
        .get(ApiEndpoints.AUTH.me, {
          headers: {
            Authorization: storedToken
          }
        })
        .then(async response => {
          setLoading(false)
          let user = { ...response.data.user }
          setUser({ ...user })
          window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
           // Fetch un-read notification count
           getUnReadNotificationCount();
        })
        .catch(() => {
          localStorage.removeItem(authConfig.storageUserDataKeyName)
          localStorage.removeItem(authConfig.storageTokenKeyName)
          setUser(null)
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }
  useEffect(() => {
    initAuth()
  }, [])

  const handleLogin = ({ accessToken, user }) => {
    // Store the token and set the user
    window.localStorage.setItem(authConfig.storageTokenKeyName, accessToken)
    setUser(user)
    // Get the redirect URL if any
    const redirectUrl = searchParams.get('redirect')
    // Check if the user has a cardNumber, if not redirect to the profile page
    if (!user?.cardNumber || user?.cardNumber === "") {
      // Redirect to profile page if no card details
      navigate(redirectUrl || '/profile')
    } else {
      // Optionally, navigate to another page if card details are available
      navigate(redirectUrl || '/rug-listing') // Or any other default page
    }
  }
  

  const handleLogout = () => {
    setUser(null)
    setIsInitialized(false)
    window.localStorage.removeItem(authConfig.storageUserDataKeyName)
    window.localStorage.removeItem(authConfig.storageTokenKeyName)
    navigate('/')
  }

  const handleSignUp = () => {}

  const initVerifyOTP = data => {
    setOtpVerifyData(data)
    navigate('/verify-otp')
  }

  const handleVerifyOtp = (type, params) => {
    axiosInstance
      .post(ApiEndpoints.OTP.verifyotp(type), params)
      .then(response => {
        toast.success(response.data.message)
        localStorage.setItem(authConfig.storageTokenKeyName, response.data.accessToken)
        let user = { ...response.data.data.user }
        window.localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(user))
        setUser({ ...user })
        navigate('/home')
        getUnReadNotificationCount();
        // Fetch un-read notification count
      })
      .catch(err => {
        toast.error(err.response.data.message)
      })
  }

  const values = {
    user,
    loading,
    otpVerifyData,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    verifyOtp: handleVerifyOtp,
    logout: handleLogout,
    signup: handleSignUp,
    initVerifyOTP: initVerifyOTP,
    unReadNotificationCount,
    setUnReadNotificationCount,
    getUnReadNotificationCount: getUnReadNotificationCount,
  }

  return <AuthContext.Provider value={values}>{loading ? <FallbackSpinner /> : children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
