import { Badge, Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/useAuth";
import BellOutline from 'mdi-material-ui/BellOutline'
import { useDispatch, useSelector } from "react-redux";
import { checkAuthentication } from 'src/redux/auth.Slice'
import { useEffect, useState } from "react";
import DialogRegister from "src/views/dialog/DialogRegister";
import { GoogleOAuthProvider } from "@react-oauth/google";

function NotificationDropdown() {
  const navigate = useNavigate();
  const { unReadNotificationCount } = useAuth();
  const [registerFormDialogOpen, setRegisterFormDialogOpen] = useState(false);

  const toggleRegisterFormDialog = () => {
    setRegisterFormDialogOpen(prev => !prev);
  };

  const dispatch = useDispatch()
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
  const handleClick = () => {
    if (isAuthenticated) {
      navigate("/notification");
    } else {
      setRegisterFormDialogOpen(true);
    }
  };
  useEffect(() => {
    dispatch(checkAuthentication())
  }, [dispatch, isAuthenticated])
  return (
    <>
      <IconButton onClick={handleClick} sx={{ p: 0 }}>
        <Badge
          color="error"
          overlap="circular"
          variant="dot"
          invisible={unReadNotificationCount > 0 ? false : true}
        >
          <Box
            sx={{
              width: '48px',  // adjust size as needed
              height: '48px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%', // makes the background round
              backgroundColor: '#f0f0f0', // choose your preferred color
            }}
          >
            <BellOutline sx={{ fontSize: '32px', color: '#905A47' }} /> {/* Adjust icon size and color */}
          </Box>
        </Badge>
      </IconButton>
      <GoogleOAuthProvider clientId="529194712210-st50mcevrkq97s51kpdf1lhse606tsep.apps.googleusercontent.com">
        <DialogRegister
          open={registerFormDialogOpen}
          toggle={toggleRegisterFormDialog}
          setRegisterFormDialogOpen={setRegisterFormDialogOpen}
        />
      </GoogleOAuthProvider>

    </>
  );
}
export default NotificationDropdown;
