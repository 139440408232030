// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { axiosInstance } from "src/network/adapter";
import { ApiEndpoints } from "src/network/endpoints";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyA-Icr4BO9QhdVyTHf-2cPxWBmmgQBuHAc",
  authDomain: "rug-auction-web.firebaseapp.com",
  projectId: "rug-auction-web",
  storageBucket: "rug-auction-web.firebasestorage.app",
  messagingSenderId: "680702076741",
  appId: "1:680702076741:web:6b3b6eef3c7035bef3402c",
  measurementId: "G-3JQG94Y3JE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
// const analytics = getAnalytics(app);

export const getNotificationPermission = async (setTokenFound) => {
  // debugger;
  const permission = await Notification.requestPermission();


  if (permission === "granted") {
    setTokenFound(true);
    const token = await getToken(messaging, {
      vapidKey:
        "BOD11YBhdn4c6oUmdIX21nyQXmrTraTyCchrE1Gi9haf2q4YKaJd1ZxdDJ3OL8Crl7vTqHe_sKZc9vTwNL80-FI",
    });
    if (token) {
      axiosInstance
        .post(ApiEndpoints.NOTIFICATIONS.sent_fcm_token, {
          token: token,
        })
        .then((response) => response.data)
        .then((response) => {
        })
        .catch((error) => {
          console.error();
        })
        .finally(() => { });
    }
  } else {
    setTokenFound(false);
  }
};

// export const getNotificationPermission = async (setTokenFound) => {
//   await Notification.requestPermission().then((permission) => {
//     console.log("permission", permission);
//     if(permission === "denied"){
//       setTokenFound(false);
//     }
//     else{
//       setTokenFound(true);
//     }
//   });
// };
