// ** Icon imports
import HomeIcon from 'mdi-material-ui/Home'

const navigation = () => {
  return [
    {
      title: 'Home',
      path: '/'
    },
    {
      title: 'Collections',
      path: '/collections'
    },
    {
      title: 'Upcoming Auctions',
      path: '/upcoming-auctions'
    },
    {
      title: 'About Us',
      path: '/about-us'
    },
    {
      title: 'Contact Us',
      path: '/contact-us'
    },
  ]
}

export default navigation
