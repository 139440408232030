import React from 'react'
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import ChatBot from '../common/chatBot';

function TabComponent() {
    return (
        <Box sx={{ '& > :not(style)': { m: 0 } }}>
            {/* <Link to="/contact-us" style={{ textDecoration: 'none' }}  >
                <Fab color="primary" size="large" aria-label="add" style={{ position: 'fixed', bottom: '50px', right: '24px', backgroundColor: '#5B2E1E' }}>
                    <WhatsAppIcon />
                    <img src={'Chat'} alt='' width={"100%"} height={"100%"} />
                </Fab>
            </Link> */}
            <ChatBot />
        </Box>
    )
}

export default TabComponent