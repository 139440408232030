import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
  },
  reducers: {
    setAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setAuthentication } = authSlice.actions;

export const checkAuthentication = () => (dispatch) => {
  const token = localStorage.getItem('rug-auctions-accessToken');
  // console.log("token",token);
  if (token!== null) {
    // console.log("test",token);
    dispatch(setAuthentication(true));
  } else {
    dispatch(setAuthentication(false));
  }
};

export default authSlice.reducer;
