const { useEffect } = require("react");

function ChatBot() {
    useEffect(() => {
        (function (d, w, c) {
            w.ChatraID = 'vJ3Eo75TmH9TvS9tz';
            var s = d.createElement('script');
            w[c] = w[c] || function () {
                (w[c].q = w[c].q || []).push(arguments);
            };
            s.async = true;
            s.src = 'https://call.chatra.io/chatra.js';
            if (d.head) d.head.appendChild(s);
        })(document, window, 'Chatra');
        window.ChatraSetup = {
            colors: {
                buttonBg: '#5B2E1E'
            }
        };
    }, [])
}

export default ChatBot;