// ** Next Imports
import { NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import { styled, useTheme } from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'
import Arrow from '../../.../../../../../assets/images/arrow.png'
// ** Configs Import
import themeConfig from 'src/configs/themeConfig'

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'

// ** Utils
import { handleURLQueries } from 'src/@core/layouts/utils'
import { useEffect, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import { Menu, MenuItem } from '@mui/material'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import { toastError } from 'src/utils/utils'

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  borderRadius: 100,
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    '&, &:hover': {
      boxShadow: theme.shadows[3],
      backgroundColor: theme.palette.primary.main,
    },
    '& .MuiTypography-root, & .MuiListItemIcon-root': {
      color: `${theme.palette.common.white} !important`,
    }
  }
}))

const MenuItemTextMetaWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' })
})

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  collapsedNavWidth,
  toggleNavVisibility,
  navigationBorderWidth
}) => {
  // ** Hooks
  const theme = useTheme()
  const navigate = useNavigate();
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isDropdownItem = item.title === 'Collections'
  const hasPath = !!item.path
  // ** Vars
  const { skin, navCollapsed } = settings
  const IconTag = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

  const conditionalBgColor = () => {
    if (skin === 'semi-dark' && theme.palette.mode === 'light') {
      return {
        color: `rgba(${theme.palette.customColors.dark}, 0.87)`,
        '&:hover': {
          backgroundColor: `rgba(${theme.palette.customColors.dark}, 0.04)`
        }
      }
    } else if (skin === 'semi-dark' && theme.palette.mode === 'dark') {
      return {
        color: `rgba(${theme.palette.customColors.light}, 0.87)`,
        '&:hover': {
          backgroundColor: `rgba(${theme.palette.customColors.light}, 0.04)`
        }
      }
    } else return {}
  }

  const isNavLinkActive = () => {
    if (location.pathname === item.path || handleURLQueries({ ...location, query: searchParams }, item.path)) {
      return true
    } else {
      return false
    }
  }
  const [collectionData, setCollectionData] = useState([])
  // Pre-fetch collection data on component mount
  // useEffect(() => {
  //   if (isDropdownItem) {
  //     axiosInstance
  //       .get(ApiEndpoints.COLLECTION.list)
  //       .then((response) => {
  //         setCollectionData(response.data)
  //       })
  //       .catch((error) => {
  //         toastError(error)
  //       })
  //   }
  // }, [isDropdownItem])

  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget) // Menu opens instantly
  }

  const handleClose = (event) => {
    event.preventDefault();
    setAnchorEl(null);
  };
  return (
    // <CanViewNavLink CanViewNavLink navLink={item} >
    <ListItem
      disablePadding
      className='nav-link'
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: '0 !important' }}
    >
      <MenuNavLink
        to={hasPath ? item.path : undefined} // Only navigate if there is a path
        component={hasPath ? NavLink : 'div'} // Use NavLink if there is a path, otherwise a div
        // onClick={isDropdownItem ? handleClick : null} // Only handle click for dropdown
        className={location.pathname === item.path ? 'active' : ''}
        sx={{
          py: 2.25,
          pl: navCollapsed && !navHover ? (collapsedNavWidth - navigationBorderWidth - 24) / 8 : 5.5,
          pr: navCollapsed && !navHover ? ((collapsedNavWidth - navigationBorderWidth - 24) / 2 - 5) / 4 : 3.5
        }}
      >
        <MenuItemTextMetaWrapper
          sx={{
            ...(navCollapsed && !navHover ? { opacity: 0 } : { opacity: 1 })
          }}
        >
          <Typography noWrap>{item.title}</Typography>
        </MenuItemTextMetaWrapper>

        {/* {isDropdownItem && (
          <img
            src={Arrow}
            alt='arrow'
            onClick={handleClick}
          />
        )} */}
      </MenuNavLink>
      {/* {isDropdownItem && (
        <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
          {collectionData.data && collectionData.data.map((collection) => (
            <MenuItem
              key={collection._id}
              onClick={(e) => {
                e.preventDefault()
                navigate("/rug-listing")
                handleClose(e)
              }}
            >
              {collection.title}
            </MenuItem>
          ))}
        </Menu>
      )} */}
    </ListItem>

    // </CanViewNavLink >
  )
}

export default VerticalNavLink
