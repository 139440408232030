import { Button, Container, FormControl, FormHelperText, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { axiosInstance } from 'src/network/adapter'
import { ApiEndpoints } from 'src/network/endpoints'
import { toastError, toastSuccess } from 'src/utils/utils'

const validationSchema = yup.object().shape({
    name: yup.string().trim().required('Name is required'),
    email: yup.string().trim().email('Invalid email').required('Email is required'),
    mobile_number: yup
        .string()
        .trim()
        .matches(/^\+?\d{8,15}$/, 'Invalid phone number')
        .required('Phone Number is required'),
    message: yup.string().trim().required('Message is required')
})

const ContactForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [loading, setLoading] = useState(false)
    const [bio, setBio] = useState('')
    const [remainingChars, setRemainingChars] = useState(250)
    const handleBioChange = e => {
        const inputText = e.target.value
        setBio(inputText)

        // Update remaining characters count
        const remainingCharsCount = 250 - inputText.length
        setRemainingChars(remainingCharsCount)
    }
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange'
    })
    const onSubmit = data => {
        setLoading(true)
        let payload = {
            name: data.name,
            email: data.email,
            mobile_number: data.mobile_number,
            message: data.message
        }
        axiosInstance
            .post(ApiEndpoints.CONTACTUS.add, payload)
            .then(response => response.data)
            .then(response => {
                toastSuccess(response.message)
            })
            .catch(error => {
                toastError(error)
            })
            .finally(() => {
                setLoading(false)
                reset({ name: '', email: '', mobile_number: '', message: '' })
                setBio('') // Reset the bio state
                setRemainingChars(250) // Reset the character count
            })
    }
    return (
        <>
            <form id='add' onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction='column'>
                    <Grid item container xs={12} spacing={4} >
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    name='name'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField size='small' variant='outlined'
                                            error={Boolean(errors.name)} {...field} placeholder='Enter your  name*' />
                                    )}
                                />
                                {errors.name && (
                                    <FormHelperText sx={{ color: '#ff0000' }}>{errors.name.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    name='email'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField size='small' variant='outlined' error={Boolean(errors.email)} {...field} placeholder='Enter your email*' />
                                    )}
                                />
                                {errors.email && (
                                    <FormHelperText sx={{ color: '#ff0000' }}>{errors.email.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    name='mobile_number'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField size='small'
                                            error={Boolean(errors.mobile_number)}
                                            {...field}
                                            placeholder='Enter your phone number*'
                                            variant='outlined'
                                        />
                                    )}
                                />
                                {errors.mobile_number && (
                                    <FormHelperText sx={{ color: '#ff0000' }}>{errors.mobile_number.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    name='message'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField size='small'
                                            sx={{
                                                // border: '1px solid #905A47',
                                                // borderRadius: '20px !important',
                                                '& .MuiInputBase-input::placeholder': {
                                                    color: '#000000',
                                                },
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius: '20px !important',
                                                    '& fieldset': {
                                                        borderColor: '#905A47',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#905A47',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#905A47',
                                                    },
                                                },
                                            }}
                                            error={Boolean(errors.message)}
                                            {...field}
                                            multiline
                                            rows={3}
                                            placeholder='Enter your message*'
                                            value={bio}
                                            // onChange={handleBioChange}
                                            onChange={e => {
                                                field.onChange(e)
                                                handleBioChange(e)
                                            }}
                                        />
                                    )}
                                />
                                {errors.message && (
                                    <FormHelperText sx={{ color: '#ff0000' }}>{errors.message.message}</FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} sx={{ display: 'flex', gap: 2 }}>
                            <Button size='small'
                                sx={{
                                    textTransform: 'none',
                                    background: '#000000',
                                    '&:hover': {
                                        bgcolor: '#000000'
                                    }
                                }}
                                type='submit'
                                variant='contained'
                                disabled={loading}
                                id='add'
                            >
                                {loading ? 'Submitting...' : 'Submit'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default ContactForm
